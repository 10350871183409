export const routes = [
    {
        path: '/login',
        name: 'login',
        component: () => import('@/views/login/login-index.vue')
    },
    {
        path: '/',
        name: 'home',
        component: () => import('@/views/home/home-index.vue'),
        redirect: '/management-info/remit-table',
        children: [
            {
                path: 'management-info/new-student',
                name: 'new-student',
                component: () => import('@/components/management/new-student.vue')
            },
            {
                path: 'management-info/login-clue',
                name: 'login-clue',
                component: () => import('@/components/management/login-clue.vue')
            },
            {
                path: 'management-info/message-config',
                name: 'message-config',
                component: () => import('@/components/management/message-config.vue')
            },
            {
                path: 'management-info/remit-table',
                name: 'remit-table',
                component: () => import('@/components/management/remit-table.vue')
            },
            {
                path: 'management-info/chart-map',
                name: 'chart-map',
                component: () => import('@/components/management/chart-map/index-map.vue')
            },
            {
                path: 'management-info/baidu-table',
                name: 'baidu-table',
                component: () => import('@/components/management/baidu-table.vue')
            },
            {
                path: 'management-info/platform-table',
                name: 'platform-table',
                component: () => import('@/components/management/platform-table.vue')
            },
            {
                path: 'management-info/arrears-table',
                name: 'arrears-table',
                component: () => import('@/components/management/arrears-table.vue')
            },
            {
                path: 'management-info/drawback-table',
                name: 'drawback-table',
                component: () => import('@/components/management/drawback-table.vue')
            },
            {
                path: 'management-info/handle-refunded',
                name: 'handle-refunded',
                component: () => import('@/components/management/handle-refunded/index-view.vue')
            },
            {
                path: 'management-info/refunded-table',
                name: 'refunded-table',
                component: () => import('@/components/management/refunded-table.vue')
            },
            {
                path: 'management-info/treat-table',
                name: 'treat-table',
                component: () => import('@/components/management/treat-table.vue')
            },
            {
                path: 'management-info/enroll-situation',
                name: 'enroll-situation',
                component: () => import('@/components/management/enroll-situation.vue')
            },
            {
                path: 'management-info/my-clue',
                name: 'my-clue',
                component: () => import('@/components/management/my-clue.vue')
            },
            {
                path: 'management-info/open-sea',
                name: 'open-sea',
                component: () => import('@/components/management/open-sea.vue')
            },
            {
                path: 'user-manage/exit-password',
                name: 'exit-password',
                component: () => import('@/components/userManage/exit-password.vue')
            },
            {
                path: 'user-manage/account-manage',
                name: 'account-manage',
                component: () => import('@/components/userManage/account-manage.vue')
            },
            {
                path: 'user-manage/system-dispose',
                name: 'system-dispose',
                component: () => import('@/components/userManage/system-dispose.vue')
            },
            {
                path: 'login-info/contract-config',
                name: 'contract-config',
                component: () => import('@/components/login-info/contract-config.vue')
            },
            {
                path: 'login-info/login-log',
                name: 'login-log',
                component: () => import('@/components/login-info/login-log.vue')
            },
            {
                path: 'anument-info/abutment-record',
                name: 'abutment-record',
                component: () => import('@/components/abument-info/abutment-record.vue')
            },
            {
                path: 'anument-info/abutment-tuiguang',
                name: 'abutment-tuiguang',
                component: () => import('@/components/abument-info/abutment-tuiguang.vue')
            },
            {
                path: 'anument-info/sales-info',
                name: 'sales-info',
                component: () => import('@/components/abument-info/sales-info.vue')
            },
            {
                path: 'anument-info/bind-account',
                name: 'bind-account',
                component: () => import('@/components/abument-info/bind-account.vue')
            },
            {
                path: 'web-site/module-upload',
                name: 'module-upload',
                component: () => import('@/components/webSite/moduleUpload/index-view.vue')
            },
            {
                path: 'web-site/module-manage',
                name: 'module-manage',
                component: () => import('@/components/webSite/moduleManage/index-view.vue')
            },
            {
                path: 'web-site/web-banner',
                name: 'web-banner',
                component: () => import('@/components/webSite/webBanner/index-view.vue')
            },
            {
                path: 'web-site/payment-list',
                name: 'payment-list',
                component: () => import('@/components/webSite/paymentList/index-view.vue')
            },
            {
                path: 'anument-info/abutment-config',
                name: 'abutment-config',
                component: () => import('@/components/abument-info/abutment-config.vue')
            },
            
        ]
    },
]